<template>
  <div class="messageList">
    <div class="panel-title">
      {{ $t("message.notificationList") }}
    </div>

    <el-tabs v-model="activeTypeId" @tab-click="tabClick" style="margin-top:20px;">
      <template v-for="item in messageTypeList">
        <el-tab-pane :label="item.messageTypeName" :name="String(item.messageTypeId)">
          <div class="result-background">
            <div class="result-table">
              <div v-loading="loading">
                <nodata v-if="messageData.length === 0" />

                <div class="notification-container" v-else>
                  <!-- <notification
                  v-for="item in messageData"
                  :key="item.messageId"
                  :message="item"
                  @click.native="handleClick(item)"
                /> -->

                  <div
                    class="messageGroup"
                    direction="vertical"
                    indicator-position="none"
                    height="74px"
                    v-if="messageData.length > 0"
                  >
                    <template v-for="item in messageData">
                      <!-- <notification :message="item" :messageType="messageType" @click.native="goNoticeDetails(item)"></notification> -->
                      <div class="messageItem">
                        <el-row :gutter="10">
                          <el-col :span="20" class="left" @click.native="handleClick(item)">
                            <div class="title">{{ item.messageTitle }}</div>
                            <div class="time">{{ item.createDate }}</div>
                          </el-col>
                          <el-col :span="4" class="right">
                            <span class="newTag">NEW</span>
                            <!-- <span class="closeBtn" @click="showMessage = false"></span> -->
                          </el-col>
                        </el-row>
                      </div>
                    </template>
                  </div>
                </div>

                <el-pagination
                  v-if="messageData.length > 0"
                  background
                  layout=" prev, pager, next, sizes, total"
                  :total="total"
                  :page-size="currentPageSize"
                  :page-sizes="[10, 15, 20]"
                  :current-page="currentPage"
                  @current-change="currentChange"
                  @size-change="sizeChange"
                  class="common-pagination"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </template>
    </el-tabs>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div class="setting-nav-title">{{ detailInfo.messageTitle }}</div>
      <div class="messageContent">
        <div class="other-notice-date">{{ detailInfo.createDate }}</div>
        <div class="ql-editor" v-html="detailInfo.messageBody" v-if="detailDrawer"></div>
      </div>
    </side-layer>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import Notification from "@/components/Notification";
import { messageListForWeb, getReceiveMessageType } from "api/message";

export default {
  mixins: [pagination],
  components: {
    Notification
  },
  data() {
    return {
      loading: true,
      activeTypeId: "4",
      activeIndex: "important",
      messageType: [1],
      messageTypeList: [],
      messageData: [],
      detailDrawer: false,
      detailInfo: {}
    };
  },
  methods: {
    tabClick($tab) {
      this.messageData = [];
      this.activeTypeId = $tab.name;
      // this.activeIndex === "important" ? (this.messageType = [1]) : (this.messageType = [2, 3]);
      this.getList();
    },

    handleClick(data) {
      this.detailInfo = data;
      this.detailDrawer = true;
    },

    async getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = pageIndex;
      let messageType = this.activeTypeId;
      await messageListForWeb({ messageType, pageIndex, pageSize, IgnoreExpiration: true }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.total = response.totalCount;
          this.messageData = response.result;
          this.loading = false;
        }
      });
    }
  },

  mounted() {
    let messageType = getReceiveMessageType();
    messageType
      .then(res => {
        console.log("messageType", res);
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.messageTypeList = res.result;
          if (this.messageTypeList.length > 0) {
            this.activeTypeId = String(this.messageTypeList[0].messageTypeId);
          }
        }
      })
      .then(() => {
        if (this.$route.query.activeTypeId) {
          console.log("找到参数 activeTypeId", this.$route.query.activeTypeId);
          this.activeTypeId = this.$route.query.activeTypeId;
        }
        this.getList().then(() => {
          if (this.$route.query.currentMessageId) {
            if (this.messageData.length > 0) {
              let data = this.messageData.find(m => m.messageId == this.$route.query.currentMessageId);
              if (data) {
                this.handleClick(data);
              } else {
                console.log("没找到数据，请确认从正确的页面点击消息");
              }
            } else {
              console.log("没找到数据，请确认从正确的页面点击消息");
            }
          }
        });
      });
  }
};
</script>

<style lang="scss">
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
@import "~@/styles/vue2-editor.css";

.setting-nav-title {
  word-break: break-all;
}
.notification-container {
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(103, 95, 119, 0.1);
  border-radius: 8px;
  padding: 10px 24px;
  box-sizing: border-box;
  margin-top: 24px;

  ::v-deep & > div {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.12);

    &:last-child {
      border: none;
    }
  }
}

.other-notice-body {
  // height: 66px;
  border-bottom: 1px dotted #d8d8d8;
  cursor: pointer;
}
.other-notice-title {
  word-break: break-all;
  line-height: 36px;
}
.other-notice-date {
  color: #86858a;
  font-size: 12px;
  line-height: 16px;
}
.other-notice-body:last-of-type {
  border: 0;
}
</style>
